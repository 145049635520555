import React, { useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { Box, Button, Stack, Typography } from '@mui/material'
import { useUser } from '@trr/app-shell-data'
import { useGlobalDrawer } from '@local/Components/Drawer/Hooks/useGlobalDrawer'
import { FormikProps } from 'formik'
import { HourglassBottom } from '@mui/icons-material'

import { useGenerateCvQuery } from '../../../Api/generateCvApi'
import { useSelectedJobId } from '../../../Utils/Hooks/useSelectedJobId'

import { mockCv } from './mockGeneratedCv'
import { CreateCvForm } from './CreateCV/CreateCvForm'
import { CvPdf } from './Pdf/CvPdf'
import { Cv } from './GeneratedCv.types'

const DEBUG = false

export const GeneratedCv: React.FC = () => {
  const selectedJobId = useSelectedJobId()
  const { setDrawerContent, openDrawer } = useGlobalDrawer()

  const user = useUser()

  const {
    data: fetchedCv,
    error,
    isLoading,
  } = useGenerateCvQuery(
    { adId: selectedJobId, body: { text: '' } },
    { skip: DEBUG }
  )

  const generatedCv = DEBUG ? mockCv : fetchedCv

  const cv: Cv = {
    ...generatedCv,
    fullname: `${user?.firstName} ${user?.lastName}`,
    email: user?.email,
    phone: user?.mobilePhone,
    profileImage: user?.profileImages?.xlarge,
    skills: [
      ...(generatedCv?.skills ?? []),
      ...(generatedCv?.otherCompetences ?? []),
      ...(generatedCv?.languages ?? []),
    ],
  }

  const [formRef, setFormRef] = useState<FormikProps<Cv>>()

  useEffect(() => {
    if (!isLoading) {
      setDrawerContent({
        title: 'Redigera',
        content: (
          <CreateCvForm
            cv={cv}
            ref={(newRef: FormikProps<Cv>) => setFormRef(newRef)}
          />
        ),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  if (isLoading) return <CircularProgress />
  if (!cv || !formRef?.values) return <div>No data</div>
  if (error && 'originalStatus' in error && error.originalStatus === 400)
    return (
      <Stack gap={5} mt={5}>
        <HourglassBottom
          sx={{
            alignSelf: 'center',
            animation: 'spin 2s ease-in-out infinite',
            transform: 'rotate(180deg) scale(2)',
            '@keyframes spin': {
              '0%': { transform: 'rotate(0deg) scale(2)' },
              '20%': { transform: 'rotate(0deg) scale(2)' },
              '80%': { transform: 'rotate(180deg) scale(2)' },
              '100%': { transform: 'rotate(180deg) scale(2)' },
            },
          }}
        />
        <Typography>
          Vi analyserar fortfarande CV:t i din matchningsprofil, vänligen försök
          igen om en stund.
        </Typography>
        <Button onClick={() => window.location.reload()} variant="contained">
          Ladda om sidan
        </Button>
      </Stack>
    )

  return (
    <Box>
      <CvPdf cv={formRef?.values} onEditClick={() => openDrawer()} />
    </Box>
  )
}
