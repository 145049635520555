import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import getConfig from '@local/Utils/getConfig'

import { prepareHeaders } from './prepareHeaders'

const { API_URL } = getConfig()

// initialize an empty api service that we'll inject endpoints into later as needed
export const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}`,
    prepareHeaders,
  }),
  endpoints: () => ({}),
  tagTypes: [
    'Profile',
    'JobAd',
    'JobAdsListing',
    'SavedJobs',
    'HistoricSearches',
    'MatchedJobs',
    'AppliedJobs',
    'QuickApplication',
  ],
  reducerPath: 'baseApi',
})
