import { Content } from '../../App.types'

export const fallbackContent: Required<Content> = {
  intresserad: {
    emptyResult: {
      title: 'emptyResult.title',
      body: 'emptyResult.body',
    },
    urlKey: 'intresserad.urlKey',
  },
  selectedViewNy: {
    jobadContent: {
      jobHeadline: 'jobadContent.jobHeadline',
      emailHeadline: 'jobadContent.emailHeadline',
      emailBody: 'jobadContent.emailBody',
      roleTitle: 'jobadContent.roleTitle',
      numberOfOpenings: 'jobadContent.numberOfOpenings',
      employerHeadline: 'jobadContent.employerHeadline',
      publicAdOverline: 'jobadContent.publicAdOverline',
      trrAdOverline: 'jobadContent.trrAdOverline',
      recruiterText: 'jobadContent.recruiterText',
      contactTab: 'jobadContent.contactTab',
      jobAdTab: 'jobadContent.jobAdTab',
    },
    callToAction: {
      lastApplicationDate: 'callToAction.lastApplicationDate',
      title: 'callToAction.title',
      applyButtonEmail: 'callToAction.applyButtonEmail',
      applyButtonText: 'callToAction.applyButtonText',
      anonymousEmployer: 'callToAction.anonymousEmployer',
      viaEmployer: 'callToAction.viaEmployer',
      publicatedText: 'callToAction.publicatedText',
      hasAppliedLabel: 'callToAction.hasAppliedLabel',
      hasAppliedHelperText: 'callToAction.hasAppliedHelperText',
      applyButtonQuickApplication: 'callToAction.applyButtonQuickApplication',
      applicationDeadlineHeading: 'callToAction.applicationDeadlineHeading',
      saveLabel: 'callToAction.saveLabel',
      quickApplicationTitle: 'callToAction.quickApplicationTitle',
      quickApplicationDescription: 'callToAction.quickApplicationDescription',
      ongoingApplication: 'callToAction.ongoingApplication',
    },
    generalInfo: {
      employerLabel: 'generalInfoContent.employerLabel',
      locationLabel: 'generalInfoContent.locationLabel',
      jobTitlesLabel: 'generalInfoContent.jobTitlesLabel',
      workingHoursLabel: 'generalInfoContent.workingHoursLabel',
      unknownEmployer: 'generalInfoContent.unknownEmployer',
      employmentTypeLabel: 'generalInfoContent.employmentTypeLabel',
      availablePositionsLabel: 'generalInfoContent.availablePositionsLabel',
    },
    emptyResult: {
      title: 'emptyResult.title',
      body: 'emptyResult.body',
    },
    quickApplication: {
      heading: 'quickApplication.heading',
      descriptionLong: 'quickApplication.descriptionLong',
      descriptionShort: 'quickApplication.descriptionShort',
      skillsHeading: 'quickApplication.skillsHeading',
      skillsDescriptionLong: 'quickApplication.skillsDescriptionLong',
      skillsDescriptionShort: 'quickApplication.skillsDescriptionShort',
      motivationHeading: 'quickApplication.motivationHeading',
      motivationDescriptionLong: 'quickApplication.motivationDescriptionLong',
      motivationDescriptionShort: 'quickApplication.motivationDescriptionShort',
      descriptionLinkLabel: 'quickApplication.descriptionLinkLabel',
      motivationFieldLabel: 'quickApplication.motivationFieldLabel',
      motivationHelperText: 'quickApplication.motivationHelperText',
      contactHeading: 'quickApplication.contactHeading',
      contactSettingsLinkLabel: 'quickApplication.contactSettingsButtonLabel',
      contactSettingsLink: 'quickApplication.contactSettingsLink',
      contactEmail: 'quickApplication.contactEmail',
      contactPhone: 'quickApplication.contactPhone',
      submitButtonLabel: 'quickApplication.submitButtonLabel',
      motivationRequired: 'quickApplication.motivationRequired',
      helperDialogLinkLabel: 'quickApplication.helperDialogLinkLabel',
      helperDialogTitle: 'quickApplication.helperDialogTitle',
      helperDialogCloseButtonLabel:
        'quickApplication.helperDialogCloseButtonLabel',
    },
    applicationStatus: {
      appliedExternallyHeading: 'applicationStatus.appliedExternallyHeading',
      appliedExternallyDescription:
        'applicationStatus.appliedExternallyDescription',
      appliedExternallyDateText: 'applicationStatus.appliedExternallyDateText',
      rejectedHeading: 'applicationStatus.rejectedHeading',
      rejectedDescription: 'applicationStatus.rejectedDescription',
      hiredHeading: 'applicationStatus.hiredHeading',
      hiredDescription: 'applicationStatus.hiredDescription',
      inProgressHeading: 'applicationStatus.inProgressHeading',
      inProgressDescription: 'applicationStatus.inProgressDescription',
      openApplicationButtonLabel:
        'applicationStatus.openApplicationButtonLabel',
      applicationDateText: 'applicationStatus.applicationDateText',
    },
    successfulApplicationDialog: {
      heading: 'successfulApplicationDialog.heading',
      description: 'successfulApplicationDialog.description',
      buttonLabel: 'successfulApplicationDialog.buttonLabel',
      buttonLink: '/successfulApplicationDialog.buttonLink',
      settingsLink: '/successfulApplicationDialog.settingsLink',
      settingsLinkLabel: 'successfulApplicationDialog.settingsLinkLabel',
      missingInfo: 'successfulApplicationDialog.missingInfo',
      contactHeading: 'successfulApplicationDialog.contactHeading',
    },
    quickApplicationHelperDialog: {
      mainBody: '<p>quickApplicationHelperDialog.mainBody</p>',
    },
    quickApplicationPreview: {
      heading: 'quickApplicationPreview.heading',
      previewHeading: 'quickApplicationPreview.previewHeading',
      skillsHeading: 'quickApplicationPreview.skillsHeading',
      motivationHeading: 'quickApplicationPreview.motivationHeading',
      closeButtonText: 'quickApplicationPreview.closeButtonText',
      submitButtonText: 'quickApplicationPreview.submitButtonText',
      noSkillsText: 'quickApplicationPreview.noSkillsText',
      noSkillsButtonText: 'quickApplicationPreview.noSkillsButtonText',
    },
    myQuickApplication: {
      heading: 'myQuickApplication.heading',
      skillsHeading: 'myQuickApplication.skillsHeading',
      motivationHeading: 'myQuickApplication.motivationHeading',
      applicationDateText: 'myQuickApplication.applicationDateText',
    },
    contact: {
      noContactText: 'contactTabContent.noContactText',
      nameLabel: 'contactTabContent.nameLabel',
      emailLabel: 'contactTabContent.emailLabel',
      phoneLabel: 'contactTabContent.phoneLabel',
      description: 'contactTabContent.descriptionLabel',
    },
  },
  sokNy: {
    yrkenSearch: {
      label: 'yrkenSearch.label',
      placeholder: 'yrkenSearch.placeholder',
      noResult: 'yrkenSearch.noResult',
      searchHistorySubHeader: 'yrkenSearch.searchHistorySubHeader',
      anywhere: 'yrkenSearch.anywhere',
    },
    arbetsortSearch: {
      label: 'arbetsortSearch.label',
      placeholder: 'arbetsortSearch.placeholder',
      noResult: 'arbetsortSearch.noResult',
      searchHistorySubHeader: 'arbetsortSearch.searchHistorySubHeader',
      anywhere: 'arbetsortSearch.anywhere',
    },
    additionalSearchOptions: {
      unspecifiedLocations: 'additionalSearchOptions.unspecifiedLocations',
      otherCountries: 'additionalSearchOptions.otherCountries',
    },
    sokfiltrering: {
      skills: 'sokfiltrering.skills',
      jobTitles: 'sokfiltrering.jobTitles',
      employers: 'sokfiltrering.employers',
      workingHours: 'sokfiltrering.workingHours',
      employmentTypes: 'sokfiltrering.employmentTypes',
      allFiltersOptionTitle: 'sokfiltrering.allFiltersOptionTitle',
      country: 'sokfiltrering.country',
      municipality: 'sokfiltrering.municipality',
      region: 'sokfiltrering.region',
    },
    sortBar: {
      hits: 'sortBar.hits',
      'publicationdate desc': 'sortBar.publicationdate desc',
      'applicationdeadline asc': 'sortBar.applicationdeadline asc',
      'relevance desc': 'sortBar.relevance desc',
      hitsSingular: 'sortBar.hitsSingular',
    },
    resultList: {
      jobAdLink: 'resultList.jobAdLink',
      publishedDate: 'resultList.publishedDate',
      applicationDeadline: 'resultList.applicationDeadline',
      showSearchResultsFromJobAdText:
        'resultList.showSearchResultsFromJobAdText',
      showSearchResultsFromJobAdTextStatic:
        'resultList.showSearchResultsFromJobAdTextStatic',
    },
    emptyResult: {
      title: 'emptyResult.title',
      body: 'emptyResult.body',
    },
    interviewPuff: {
      categorization: 'interviewPuff.categorization',
      title: 'interviewPuff.title',
      subtitle: 'interviewPuff.subtitle',
      to: 'interviewPuff.to',
    },
    urlKey: 'sokNy.urlKey',
  },
  ansokta: {
    resultList: {
      jobAdLink: 'resultList.jobAdLink',
      recruitmentStatusTextHired: 'resultList.recruitmentStatusTextHired',
      recruitmentStatusTextRejected: 'resultList.recruitmentStatusTextRejected',
      recruitmentStatusTextApplied: 'resultList.recruitmentStatusTextApplied',
      appliedDateText: 'resultList.appliedDateText',
      highlightedTextPublic: 'resultList.highlightedTextPublic',
      highlightedTextTrr: 'resultList.highlightedTextTrr',
    },
    emptyResult: {
      title: 'emptyResult.title',
      body: 'emptyResult.body',
    },
    sortBar: {
      hits: 'sortBar.hits',
      'publicationdate desc': 'sortBar.publicationdate desc',
      'applicationdeadline asc': 'sortBar.applicationdeadline asc',
      'applieddate desc': 'sortBar.applieddate desc',
      hitsSingular: 'sortBar.hitsSingular',
    },
    urlKey: 'ansokta.urlKey',
  },
  sparadeNy: {
    sortBar: {
      hits: 'sortBar.hits',
      'publicationdate desc': 'sortBar.publicationdate desc',
      'applicationdeadline asc': 'sortBar.applicationdeadline asc',
      'saveddate desc': 'sortBar.saveddate desc',
      hitsSingular: 'sortBar.hitsSingular',
    },
    emptyResult: {
      title: 'emptyResult.title',
      body: 'emptyResult.body',
    },
    urlKey: 'sparadeNy.urlKey',
  },
  matchade: {
    resultList: {
      jobAdLink: 'resultList.jobAdLink',
    },
    urlKey: 'matchade.urlKey',
  },
  matchadeNy: {
    profileBox: {
      profileLink: 'profileBox.profileLink',
      title: 'profileBox.title',
      body: 'profileBox.body',
      profileLinkText: 'profileBox.profileLinkText',
      noResultTitle: 'profileBox.noResultTitle',
      noResultBody: 'profileBox.noResultBody',
      noResultBodyWithSuggestions: 'profileBox.noResultBodyWithSuggestions',
      noResultLinkText: 'profileBox.noResultLinkText',
      noResultLinkTextWithSuggestions:
        'profileBox.noResultLinkTextWithSuggestions',
      noProfileTitle: 'profileBox.noProfileTitle',
      noProfileBody: 'profileBox.noProfileBody',
      noProfileLinkText: 'profileBox.noProfileLinkText',
      endedProfileTitle: 'profileBox.endedProfileTitle',
      endedProfileBody: 'profileBox.endedProfileBody',
    },
    sortBar: {
      'publicationdate desc': 'sortBar.publicationdate desc',
      'applicationdeadline asc': 'sortBar.applicationdeadline asc',
      'relevance desc': 'sortBar.relevance desc',
      countSingular: 'sortBar.countSingular',
      countPlural: 'sortBar.countPlural',
    },
    urlKey: 'matchadeNy.urlKey',
  },
}
