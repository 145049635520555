import { baseApi } from './baseApi'

interface IMatchingProfileResponse {
  nonCompletedWizardSteps: unknown[]
}

export const profileApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    hasCompletedMatchingProfile: builder.query<boolean, string>({
      query: (clientId) => `/client-match/profiles/${clientId}`,
      transformResponse: (baseQueryReturnValue: IMatchingProfileResponse) => {
        return baseQueryReturnValue.nonCompletedWizardSteps.length === 0
      },
    }),
  }),
})

export const { useHasCompletedMatchingProfileQuery } = profileApi
