import React, { PropsWithChildren, useEffect, useState } from 'react'
import { usePDF } from '@react-pdf/renderer'
import { Document, Page, pdfjs } from 'react-pdf'
import * as pdfjsWorker from 'pdfjs-dist/build/pdf.worker.mjs'
import { Box, CircularProgress, Typography } from '@mui/material'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  pdfjsWorker,
  import.meta.url
).toString()

interface ViewerProps {
  doc: React.JSX.Element
  width?: number
}

export const Viewer: React.FC<PropsWithChildren<ViewerProps>> = ({
  doc,
  width,
}) => {
  const [pdfString, setPdfString] = useState('')

  const [numPages, setNumPages] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [isDocumentLoaded, setIsDocumentLoaded] = useState(false)

  const [pdf, updatePdf] = usePDF({
    document: doc,
  })

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
    setIsDocumentLoaded(true)
  }

  useEffect(() => {
    updatePdf(doc)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doc])

  useEffect(() => {
    const reader = new FileReader()

    if (pdf.blob) {
      reader.readAsDataURL(pdf.blob)
      reader.onloadstart = () => {
        setIsLoading(true)
      }
      reader.onloadend = () => {
        const base64String =
          typeof reader.result === 'string' ? reader.result : ''
        const slicedBase64String = base64String.slice(
          base64String.indexOf(',') + 1
        )
        setPdfString(slicedBase64String)
        setIsLoading(false)
      }
    }
  }, [pdf])

  if (!pdfjs.GlobalWorkerOptions.workerSrc) {
    return <Typography>Laddar...</Typography>
  }

  if (isLoading) {
    return (
      <Box
        width={width}
        minHeight={width * 1.414}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Document
      file={`data:application/pdf;base64,${pdfString}`}
      onLoadSuccess={onDocumentLoadSuccess}
    >
      {isDocumentLoaded &&
        Array.from({ length: numPages }, (_, index) => (
          <Page
            key={index}
            renderTextLayer={false}
            pageNumber={index + 1}
            renderAnnotationLayer={false}
            width={width}
          />
        ))}
    </Document>
  )
}
