/**
 * Outputs number of calendar days between two dates
 */
const differenceInCalendarDays = (start: Date, end: Date): number => {
  const startDate = start.setHours(0, 0, 0, 0)
  const endDate = end.setHours(0, 0, 0, 0)
  const dayInTime = 1000 * 60 * 60 * 24
  const diffInTime = new Date(startDate).getTime() - new Date(endDate).getTime()
  return Math.round(diffInTime / dayInTime)
}

/**
 * Whether or not the date is before the other
 */
const isDateBefore = (date: Date, referenceDate = new Date()): boolean =>
  differenceInCalendarDays(date, referenceDate) < 0

/**
 * Format difference in days to human readable format
 */
const formatDifferenceInDays = (date: Date): string => {
  const startDate = new Date(date)
  const endDate = new Date()
  const days = differenceInCalendarDays(startDate, endDate)

  if (days === 0) return 'Idag'

  return `${Math.abs(days) < 100 ? Math.abs(days) : '99+'} ${
    Math.abs(days) === 1 ? 'dag' : 'dagar'
  } ${days < 0 ? 'sedan' : 'kvar'}`
}

/**
 * Whether or not the date is within one week in the future.
 */
const isLessThanSevenDaysLeft = (date: Date): boolean =>
  differenceInCalendarDays(new Date(date), new Date()) < 7

const isNewerThanSevenDaysOld = (date: Date): boolean =>
  differenceInCalendarDays(new Date(), date) < 7

/**
 * Format a date as if it was an application deadline.
 */
const deadlineFormater = (date: Date): string =>
  isDateBefore(date) ? 'Utgången' : formatDifferenceInDays(date)

export {
  isLessThanSevenDaysLeft,
  deadlineFormater,
  isDateBefore,
  differenceInCalendarDays,
  isNewerThanSevenDaysOld,
}
