import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import React, { FunctionComponent } from 'react'
import { CustomHighlightBox } from '@local/Components/CustomHighlightBox/CustomHighlightBox'
import { useAuthentication } from '@trr/app-shell-data'
import { OrderOption } from '@local/Api/types/api.types'
import { Stack } from '@mui/material'

import { isUserEnded } from '../../Utils/Helpers'
import { useSearchQueryParams } from '../../Utils/Hooks/useSearchQueryParams'
import { LoadingSpinner } from '../../Components/LoadingSpinner'
import usePickEpiContent, {
  replaceEpiVariables,
} from '../../Utils/Content/usePickEpiContent'
import Emptydata from '../../Components/EmptyResult/EmptyResult'
import { SearchJobListItem } from '../JobSearch/Components/SearchJobListItem'
import { JobAdsPage } from '../../Components/JobAdsPage/JobAdsPage'

import { useGetMatchedJobsQuery } from './Api/matchedJobsApi'

export const MatchedJobsPage: FunctionComponent = () => {
  const { formattedSearchParams } = useSearchQueryParams()

  const { userRoles } = useAuthentication()
  const isEnded = isUserEnded(userRoles)

  const {
    data: jobResult,
    error,
    isLoading,
  } = useGetMatchedJobsQuery(
    {
      orderBy: formattedSearchParams.orderBy as string,
      page: formattedSearchParams.page,
      pageSize: 14,
    },
    {
      refetchOnMountOrArgChange: 2,
      skip: isEnded,
    }
  )

  const {
    profileBox: {
      profileLink,
      title,
      body,
      profileLinkText,
      noResultTitle,
      noResultBody,
      noResultLinkText,
      noProfileTitle,
      noProfileBody,
      noProfileLinkText,
      endedProfileTitle,
      endedProfileBody,
    },
    sortBar,
  } = usePickEpiContent().matchadeNy

  const profileUpdateRequired = (error as FetchBaseQueryError)?.status === 404
  const showList = jobResult && jobResult?.totalHits > 0

  const formattedsortBar = [
    {
      text: sortBar['relevance desc'],
      type: OrderOption.Relevance,
    },
    {
      text: sortBar['applicationdeadline asc'],
      type: OrderOption.ApplicationDeadline,
    },
    {
      text: sortBar['publicationdate desc'],
      type: OrderOption.PublicationDate,
    },
  ]

  const hitsText = (hits = 0) =>
    replaceEpiVariables(
      hits === 1 ? sortBar.countSingular : sortBar.countPlural,
      {
        number: hits.toString(),
      }
    )

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (isEnded) {
    return (
      <Emptydata heading={endedProfileTitle} description={endedProfileBody} />
    )
  }

  if (profileUpdateRequired) {
    return (
      <section>
        <CustomHighlightBox
          heading={noProfileTitle}
          body={noProfileBody}
          linkTitle={noProfileLinkText}
          label={noProfileLinkText}
          url={profileLink}
        />
      </section>
    )
  }

  return (
    <>
      {showList ? (
        <Stack direction="column" spacing={1}>
          <CustomHighlightBox
            heading={title}
            body={body}
            linkTitle={profileLinkText}
            label={profileLinkText}
            url={profileLink}
          />
          <JobAdsPage
            jobAds={jobResult?.jobAds}
            orderOptions={formattedsortBar}
            order={OrderOption.Relevance}
            totalHits={jobResult?.totalHits}
            totalPages={jobResult?.totalPages}
            page={formattedSearchParams.page ?? 1}
            renderItem={(jobAd) => (
              <SearchJobListItem job={jobAd} showNewIndicator />
            )}
            renderHitsText={hitsText}
            EmptyResult={
              <Emptydata heading={noResultTitle} description={noResultBody} />
            }
            isLoading={isLoading}
          />
        </Stack>
      ) : (
        <CustomHighlightBox
          heading={noResultTitle}
          body={noResultBody}
          linkTitle={noResultLinkText}
          label={noResultLinkText}
          url={profileLink}
        />
      )}
    </>
  )
}
