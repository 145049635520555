import React from 'react'
import { FormControlLabel, Stack, Switch, Typography } from '@mui/material'

import { useApplyJobAdMutation } from '../../../../../Api/JobAdApi'
import usePickEpiContent from '../../../../../Utils/Content/usePickEpiContent'
import { useOptimistic } from '../../../../../Utils/Hooks/useOptimistic'

interface ApplyCheckboxProps {
  jobId: string
  hasApplied: boolean
}

export const ApplyCheckbox: React.FC<ApplyCheckboxProps> = ({
  hasApplied,
  jobId,
}) => {
  const [optimisticValue, setOptimisticValue] = useOptimistic(hasApplied)
  const { hasAppliedHelperText, hasAppliedLabel } =
    usePickEpiContent().selectedViewNy.callToAction

  const [apply] = useApplyJobAdMutation()

  const handleApply = () => {
    setOptimisticValue(!optimisticValue)
    apply({ id: jobId, method: optimisticValue ? 'DELETE' : 'POST' })
  }

  return (
    <Stack spacing={0}>
      <FormControlLabel
        checked={optimisticValue}
        onChange={handleApply}
        label={hasAppliedLabel}
        control={<Switch />}
      />
      <Typography variant="body2" color="text.secondary">
        {hasAppliedHelperText}
      </Typography>
    </Stack>
  )
}
