import { Add } from '@mui/icons-material'
import { Button, Stack, Typography } from '@mui/material'
import React from 'react'

export const EditFormActions: React.FC = () => {
  return (
    <Stack gap={2}>
      <Typography variant="h6" pt={2} pb={2}>
        Lägg till fler sektioner
      </Typography>
      <Stack gap={1.5} direction={'row'} flexWrap={'wrap'}>
        <Button color="inherit" variant={'outlined'} size={'small'}>
          Hobbies
        </Button>
        <Button color="inherit" variant={'outlined'} size={'small'}>
          Kurser
        </Button>
        <Button color="inherit" variant={'outlined'} size={'small'}>
          Länkar
        </Button>
        <Button
          variant={'outlined'}
          size={'small'}
          startIcon={<Add />}
          sx={{ textWrap: 'nowrap' }}
        >
          Skapa sektion
        </Button>
      </Stack>
    </Stack>
  )
}
