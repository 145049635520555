import { Grid, GridProps } from '@mui/material'
import React from 'react'

import CreateMatchingProfilePuff from './Puffs/CreateMatchingProfilePuff'
import SavedAdsPuff from './Puffs/SavedAdsPuff'
import TotalJobAdsPuff from './Puffs/TotalJobAdsPuff'
import MatchingAdsPuff from './Puffs/MatchingAdsPuff'
import NoMatchingAdsPuff from './Puffs/NoMatchingAdsPuff'
import InterviewPuff from './Puffs/InterviewPuff'
import usePuffGrid from './usePuffGrid'

const PuffGrid: React.FC<GridProps> = (props) => {
  const {
    hasCompletedMatchingProfile,
    matchingAdsCount,
    savedAdsCount,
    totalJobAdsCount,
    isLoading,
    hasError,
    hasCreateMatchingProfilePuffContent,
    hasSavedAdsPuffContent,
    hasMatchingAdsPuffContent,
    hasNoMatchingAdsPuffContent,
  } = usePuffGrid()

  const LeftPuff = (() => {
    if (hasCreateMatchingProfilePuffContent && !hasCompletedMatchingProfile) {
      return (
        <CreateMatchingProfilePuff data-testid="create-matching-profile-puff" />
      )
    }

    if (hasNoMatchingAdsPuffContent && !matchingAdsCount) {
      return <NoMatchingAdsPuff data-testid="no-matching-ads-puff" />
    }

    if (hasMatchingAdsPuffContent && matchingAdsCount) {
      return (
        <MatchingAdsPuff
          matchingAdsCount={matchingAdsCount}
          data-testid="matching-ads-puff"
        />
      )
    }

    return (
      <TotalJobAdsPuff
        totalJobAdsCount={totalJobAdsCount}
        data-testid="total-job-ads-puff"
      />
    )
  })()

  const RightPuff = (() => {
    if (hasSavedAdsPuffContent && savedAdsCount) {
      return (
        <SavedAdsPuff
          savedAdsCount={savedAdsCount}
          data-testid="saved-ads-puff"
        />
      )
    }

    if (hasCreateMatchingProfilePuffContent && !hasCompletedMatchingProfile) {
      return (
        <TotalJobAdsPuff
          totalJobAdsCount={totalJobAdsCount}
          data-testid="total-job-ads-puff"
        />
      )
    }

    return <InterviewPuff data-testid="interview-puff" />
  })()

  if (isLoading || hasError) {
    return null
  }

  return (
    <Grid container spacing={2} alignItems="stretch" {...props}>
      <Grid item sm={12} md={6}>
        {LeftPuff}
      </Grid>
      <Grid item sm={12} md={6}>
        {RightPuff}
      </Grid>
    </Grid>
  )
}

export default PuffGrid
