import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import React, { FunctionComponent } from 'react'
import { useAuthentication } from '@trr/app-shell-data'
import { OrderOption } from '@local/Api/types/api.types'
import { Stack } from '@mui/material'
import { useFeatureFlag } from '@local/Utils'
import PuffCard from '@local/Components/PuffCard'

import { isUserEnded } from '../../Utils/Helpers'
import { useSearchQueryParams } from '../../Utils/Hooks/useSearchQueryParams'
import { LoadingSpinner } from '../../Components/LoadingSpinner'
import usePickEpiContent, {
  replaceEpiVariables,
} from '../../Utils/Content/usePickEpiContent'
import Emptydata from '../../Components/EmptyResult/EmptyResult'
import { SearchJobListItem } from '../JobSearch/Components/SearchJobListItem'
import { JobAdsPage } from '../../Components/JobAdsPage/JobAdsPage'
import usePuffGrid from '../JobSearch/PuffGrid/usePuffGrid'
import SuggestionsBannerPuff from '../JobSearch/PuffGrid/Puffs/SuggestionsBannerPuff'

import { useGetMatchedJobsQuery } from './Api/matchedJobsApi'
import { NoMatchedJobsPuff } from './Components/NoMatchedJobsPuff'

export const MatchedJobsPage: FunctionComponent = () => {
  const { formattedSearchParams } = useSearchQueryParams()

  const { userRoles } = useAuthentication()
  const isEnded = isUserEnded(userRoles)
  const { suggestionsCount, hasSuggestionsPuffContent } = usePuffGrid()
  const { isSuggestionsBlockEnabled } = useFeatureFlag()

  const {
    data: jobResult,
    error,
    isLoading,
  } = useGetMatchedJobsQuery(
    {
      orderBy: formattedSearchParams.orderBy as string,
      page: formattedSearchParams.page,
      pageSize: 14,
    },
    {
      refetchOnMountOrArgChange: 2,
      skip: isEnded,
    }
  )

  const {
    profileBox: {
      title: profileBoxTitle,
      body: profileBoxBody,
      profileLinkText,
      profileLink,
      noProfileTitle,
      noProfileBody,
      noProfileLinkText,
      endedProfileTitle,
      endedProfileBody,
    },
    sortBar,
  } = usePickEpiContent().matchadeNy

  const profileUpdateRequired = (error as FetchBaseQueryError)?.status === 404
  const showSuggestions =
    hasSuggestionsPuffContent &&
    isSuggestionsBlockEnabled &&
    suggestionsCount > 0
  const noResults = jobResult?.totalHits === 0

  const formattedsortBar = [
    {
      text: sortBar['relevance desc'],
      type: OrderOption.Relevance,
    },
    {
      text: sortBar['applicationdeadline asc'],
      type: OrderOption.ApplicationDeadline,
    },
    {
      text: sortBar['publicationdate desc'],
      type: OrderOption.PublicationDate,
    },
  ]

  const hitsText = (hits = 0) =>
    replaceEpiVariables(
      hits === 1 ? sortBar.countSingular : sortBar.countPlural,
      {
        number: hits.toString(),
      }
    )

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (isEnded) {
    return (
      <Emptydata heading={endedProfileTitle} description={endedProfileBody} />
    )
  }

  if (profileUpdateRequired) {
    return (
      <PuffCard
        heading={noProfileTitle}
        description={noProfileBody}
        actionText={noProfileLinkText}
        href={profileLink}
      />
    )
  }

  if (noResults) {
    return <NoMatchedJobsPuff />
  }

  return (
    <Stack direction="column" spacing={1}>
      {showSuggestions ? (
        <SuggestionsBannerPuff count={suggestionsCount} />
      ) : (
        <PuffCard
          heading={profileBoxTitle}
          description={profileBoxBody}
          actionText={profileLinkText}
          href={profileLink}
        />
      )}
      <JobAdsPage
        jobAds={jobResult?.jobAds}
        orderOptions={formattedsortBar}
        order={OrderOption.Relevance}
        totalHits={jobResult?.totalHits}
        totalPages={jobResult?.totalPages}
        page={formattedSearchParams.page ?? 1}
        renderItem={(jobAd) => (
          <SearchJobListItem job={jobAd} showNewIndicator />
        )}
        renderHitsText={hitsText}
        isLoading={isLoading}
      />
    </Stack>
  )
}
