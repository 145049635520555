import {
  Box,
  BoxProps,
  Stack,
  StackProps,
  Tab,
  TabProps,
  Tabs,
  TabsProps,
} from '@mui/material'
import React from 'react'

interface DynamicTabsProps<
  TabKey extends string,
  DefaultTabKey extends TabKey,
> {
  tabs: {
    label: TabKey
    content: React.ReactNode
    tabProps?: TabProps
    contentProps?: BoxProps
  }[]
  defaultTab?: DefaultTabKey
  tabsProps?: TabsProps
  tabProps?: TabProps
  contentProps?: BoxProps
  containerProps?: StackProps
}

export const DynamicTabs = <
  TabKey extends string,
  DefaultTabKey extends TabKey,
>({
  tabs,
  defaultTab,
  tabProps,
  tabsProps,
  contentProps,
  containerProps,
}: DynamicTabsProps<TabKey, DefaultTabKey>): JSX.Element => {
  const [currentTabKey, setCurrentTabKey] = React.useState<TabKey>(
    defaultTab ?? tabs[0]?.label
  )

  const tabsWithContent = tabs.filter((tab) => !!tab.content)

  if (tabsWithContent.length === 0) return null

  if (tabsWithContent.length === 1)
    return <Box {...containerProps}>{tabsWithContent[0].content}</Box>

  const currentTab = tabsWithContent.find((tab) => tab.label === currentTabKey)

  return (
    <Stack {...containerProps}>
      <Tabs
        value={currentTabKey}
        onChange={(_, tab) => setCurrentTabKey(tab)}
        {...tabsProps}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.label}
            value={tab.label}
            onClick={() => setCurrentTabKey(tab.label)}
            label={tab.label}
            {...tabProps}
            {...tab.tabProps}
          />
        ))}
      </Tabs>
      {currentTab && (
        <Box {...contentProps} {...currentTab.contentProps}>
          {currentTab?.content}
        </Box>
      )}
    </Stack>
  )
}
