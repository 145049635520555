import React from 'react'
import { Box, Grid2 as Grid, Stack, Typography } from '@mui/material'
import { useAuthentication, useDevice } from '@trr/app-shell-data'
import { GlobalDrawerProvider } from '@local/Components/Drawer/Context/GlobalDrawerProvider'
import { AutoAwesomeRounded } from '@mui/icons-material'
import { useGetMatchProfileQuery } from '@local/Api/matchingProfileApi'

import { SavedTo } from '../../../Api/types/api.types'
import { ApplicationStatusMapper } from '../QuickApplication/ApplicationStatusCards/ApplicationStatusMapper'
import { useSelectedJobApplication } from '../Hooks/useSelectedJobApplication'
import {
  GetJobAdResponse,
  useGetJobAdStatusesQuery,
} from '../../../Api/JobAdApi'
import { DynamicTabs } from '../../../Components/DynamicTabs/DynamicTabs'
import usePickEpiContent from '../../../Utils/Content/usePickEpiContent'
import { useFeatureFlag } from '../../../Utils'
import { GeneratedCv } from '../GeneratedCv/GeneratedCv'

import { AdvertOverline } from './AdvertOverline/AdvertOverline'
import { AdvertSubtitle } from './AdvertSubtitle/AdvertSubtitle'
import { AdvertGeneralInfo } from './AdvertGeneralInfo/AdvertGeneralInfo'
import { AdvertCallToAction } from './AdvertCallToAction/AdvertCallToAction'
import { AdvertContent } from './AdvertContent/AdvertContent'
import { AdvertContact } from './AdvertContact/AdvertContact'

interface JobAdProps {
  job: GetJobAdResponse
}

const Advert: React.FC<JobAdProps> = ({ job }) => {
  const { isMobile } = useDevice()
  const { sub } = useAuthentication()
  const { isContactTabEnabled, isCvGenerationEnabled } = useFeatureFlag()

  const { jobAdTab, contactTab } =
    usePickEpiContent().selectedViewNy.jobadContent

  const { data: application, isLoading: isLoadingApplicationStatus } =
    useSelectedJobApplication()
  const { data: saveStatus, isLoading: isLoadingSaveStatus } =
    useGetJobAdStatusesQuery(job.id)
  const { data: matchProfile } = useGetMatchProfileQuery(sub, {
    skip: !isCvGenerationEnabled,
  })

  const hasMatchProfileCv = matchProfile?.cvPdf?.id

  const CallToAction = (
    <AdvertCallToAction
      jobId={job.id}
      isSaved={saveStatus?.isSaved}
      hasApplied={saveStatus?.hasApplied}
      applicationType={job.application?.type}
      applicationDeadline={
        job.application?.deadline
          ? new Date(job.application.deadline)
          : undefined
      }
      applicationInformationValue={job.application?.value}
      isLoading={isLoadingSaveStatus || isLoadingApplicationStatus}
    />
  )

  const isTrrRecruitment = job.application.type === 'TrrRecruitment'

  const applyStatus = saveStatus?.saveStatus?.find(
    (s) => s.savedTo === SavedTo.Applied
  )

  const lastUpdatedDate = isTrrRecruitment
    ? application?.lastModifiedWhen
    : applyStatus?.lastModifiedAt

  const applicationDate = isTrrRecruitment
    ? application?.createdWhen
    : applyStatus?.createdAt

  const showContactTab =
    isContactTabEnabled && isTrrRecruitment && saveStatus?.hasApplied

  const JobAdTabContent = (
    <Stack spacing={4}>
      <AdvertGeneralInfo
        employerName={job.position?.employer?.name}
        workingHours={job.position?.workingHours}
        jobTitles={job.position?.jobTitles}
        locations={[job.location?.name]}
        availablePositions={job.position?.vacancies}
        employmentType={job.position?.employmentType}
      />
      <AdvertContent
        description={job.position?.description}
        htmlDescription={job?.position?.descriptionHtml}
        companyHtmlDescription={job?.position?.employer?.descriptionHtml}
        companyName={job?.position?.employer?.name}
      />
    </Stack>
  )

  const ContactTabContent = <AdvertContact contact={job.contacts[0]} />

  return (
    <Box>
      <GlobalDrawerProvider>
        <Grid
          container
          columns={isMobile ? 2 : 3}
          columnSpacing={5}
          position="relative"
        >
          <Grid size={2}>
            <Stack spacing={4}>
              <Stack spacing={1}>
                <AdvertOverline applicationType={job?.application?.type} />
                <Typography variant="h3" sx={{ wordBreak: 'break-word' }}>
                  {job.position.header}
                </Typography>
                <AdvertSubtitle
                  publicationDate={
                    job.application?.publicationDate
                      ? new Date(job.application.publicationDate)
                      : undefined
                  }
                  recruiterName={job.position.recruiter?.name}
                />
              </Stack>
              {isMobile && CallToAction}

              {saveStatus?.hasApplied && (
                <ApplicationStatusMapper
                  status={application?.status}
                  hasAppliedExternally={!isTrrRecruitment}
                  lastUpdatedDate={
                    lastUpdatedDate ? new Date(lastUpdatedDate) : undefined
                  }
                  applicationDate={
                    applicationDate ? new Date(applicationDate) : undefined
                  }
                />
              )}
              <DynamicTabs
                tabs={[
                  {
                    label: jobAdTab,
                    content: JobAdTabContent,
                  },
                  ...(showContactTab
                    ? [
                        {
                          label: contactTab,
                          content: ContactTabContent,
                        },
                      ]
                    : []),
                  ...(isCvGenerationEnabled && hasMatchProfileCv
                    ? [
                        {
                          label: 'Anpassat CV',
                          content: <GeneratedCv />,
                          tabProps: {
                            icon: <AutoAwesomeRounded />,
                            iconPosition: 'start' as const,
                          },
                        },
                      ]
                    : []),
                ]}
                tabsProps={{
                  sx: {
                    borderBottom: '1px solid',
                    borderBottomColor: 'divider',
                  },
                }}
                tabProps={{
                  sx: { minHeight: 0, px: 5 },
                }}
                containerProps={{ spacing: 3 }}
              />
            </Stack>
          </Grid>
          {!isMobile && (
            <Grid size={1} position="relative">
              <Box position="sticky" top={(t) => t.spacing(2)}>
                {CallToAction}
              </Box>
            </Grid>
          )}
        </Grid>
        <Box id="advert-container-bottom" />
      </GlobalDrawerProvider>
    </Box>
  )
}

export default Advert
