import React, { useState } from 'react'
import {
  Drawer,
  DrawerProps,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import { ArrowBack, ArrowForward, Close } from '@mui/icons-material'
import { useDevice } from '@trr/app-shell-data'

const DRAWER_MIN_WIDTH = 520
const DRAWER_MAX_WIDTH = 1000

interface CustomDrawerProps extends DrawerProps {
  title: string
  enableDynamicWidth?: boolean
  handleClose: () => void
}

export const CustomDrawer: React.FC<CustomDrawerProps> = ({
  title,
  children,
  enableDynamicWidth = true,
  handleClose,
  ...props
}) => {
  const { isMobile } = useDevice()
  const [drawerWidth, setDrawerWidth] = useState(DRAWER_MIN_WIDTH)

  const isSmallScreen = drawerWidth < DRAWER_MAX_WIDTH
  const showExpandArrow = !isMobile && enableDynamicWidth

  const FormTitle: React.FC = () => (
    <Typography variant="h4">{title}</Typography>
  )

  return (
    <Drawer
      anchor="right"
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: isMobile ? '100vw' : drawerWidth,
          maxWidth: '100vw',
          transition: 'width 0.5s',
          p: { xs: 2, sm: 3, md: 5 },
        },
      }}
      {...props}
    >
      <Stack spacing={3} pb={3}>
        <Stack
          spacing={1}
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          {showExpandArrow && (
            <IconButton
              onClick={() =>
                setDrawerWidth(
                  isSmallScreen ? DRAWER_MAX_WIDTH : DRAWER_MIN_WIDTH
                )
              }
            >
              {isSmallScreen ? <ArrowBack /> : <ArrowForward />}
            </IconButton>
          )}
          {!showExpandArrow && <FormTitle />}
          <IconButton
            onClick={handleClose}
            data-testid="drawer-close-button"
            sx={{
              width: 45,
              height: 45,
            }}
          >
            <Close />
          </IconButton>
        </Stack>
        {showExpandArrow && <FormTitle />}
      </Stack>
      {children}
    </Drawer>
  )
}
