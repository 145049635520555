import React from 'react'
import { Typography, TypographyProps } from '@mui/material'

import {
  deadlineFormater,
  isDateBefore,
  isLessThanSevenDaysLeft,
} from '../../Utils/Helpers/dateHelpers'

interface DeadlineProps extends TypographyProps {
  date: Date
}

export const Deadline: React.FC<DeadlineProps> = ({ date, ...otherProps }) => {
  date?.setHours(23, 59, 59, 0)

  if (isLessThanSevenDaysLeft(new Date(date))) {
    return (
      <Typography
        color={isDateBefore(new Date(date)) ? 'error.main' : 'warning.dark'}
        {...otherProps}
      >
        {deadlineFormater(new Date(date))}
      </Typography>
    )
  }
}
