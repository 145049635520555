import React from 'react'
import { Document, Page, View, Text, Link, Image } from '@react-pdf/renderer'

import { Cv, Experience } from '../../GeneratedCv.types'
import { pdfStyles as s } from '../styles'
import { PdfStack } from '../Components/PdfStack'
import { PdfSection } from '../Components/PdfSection'
import { PhoneIphoneIcon } from '../Icons/PhoneIphoneIcon'
import { MailOutlineIcon } from '../Icons/MailOutlineIcon'

interface PdfDocumentProps {
  cv: Cv
}

const formatExperienceTitle = (experience, placeholder): string => {
  if (experience.title && experience.organization) {
    return `${experience.title} på ${experience.organization}`
  } else if (experience.title) {
    return experience.title
  } else if (experience.organization) {
    return experience.organization
  }
  return placeholder
}

const formatDateSubtitle = (experience: Experience): string => {
  const stringParts = []
  if (experience.startYear || experience.startMonth) {
    if (experience.startYear && experience.startMonth) {
      stringParts.push(`${experience.startYear} ${experience.startMonth}`)
    } else if (experience.startYear) {
      stringParts.push(`${experience.startYear}`)
    }
  }
  if (experience.endYear || experience.endMonth) {
    if (experience.endYear && experience.endMonth) {
      stringParts.push(`${experience.endYear} ${experience.endMonth}`)
    } else if (experience.endYear) {
      stringParts.push(`${experience.endYear}`)
    }
  } else {
    if (experience.startYear && experience.startMonth) {
      stringParts.push('Nuvarande')
    }
  }

  if (stringParts.length === 0) {
    return 'Ej angivet'
  }

  return stringParts.join(' - ')
}

const tmpContent = {
  skillsHeading: 'Kompetenser',
  referencesHeading: 'Referenser',
  workExperiencesHeading: 'Yrkeserfarenhet',
  educationsHeading: 'Utbildningar',
  contactHeading: 'Kontaktuppgifter',
  dateFormat: 'yyyy-MM-dd',
}

export const PdfDocument = ({ cv }: PdfDocumentProps) => {
  const c = tmpContent

  const GOLDEN_RATIO = 1.618

  return (
    <Document style={{ width: '100%' }}>
      <Page style={s.page} size="A4" wrap={false}>
        <PdfStack spacing={40} wrap={false}>
          <PdfStack id="header" spacing={8} style={{ flexDirection: 'row' }}>
            {cv?.profileImage && (
              <View style={{ flex: 2, marginTop: 16 }}>
                <View style={{ width: 200, height: 120 }}>
                  <Image
                    src={cv.profileImage}
                    style={{
                      width: 80,
                      height: 'auto',
                      objectFit: 'contain',
                      borderRadius: '50%',
                    }}
                  />
                </View>
              </View>
            )}
            <View style={{ flex: 9 }}>
              <Text style={[s.h1, s.gutter3]}>{cv?.fullname}</Text>
              <Text style={[s.body1, s.gutterRight]}>{cv?.summary}</Text>
            </View>
          </PdfStack>
          <PdfStack id="content" spacing={16} style={{ flexDirection: 'row' }}>
            <PdfStack
              spacing={8}
              id="main"
              style={{ flex: GOLDEN_RATIO }}
              wrap={false}
            >
              {cv?.workExperiences?.length > 0 && (
                <PdfSection
                  title={c.workExperiencesHeading}
                  font="h2"
                  wrap={false}
                  underline
                >
                  <PdfStack spacing={8} wrap={false}>
                    {cv?.workExperiences?.map((experience, index) => (
                      <PdfSection
                        key={experience.organization}
                        title={formatExperienceTitle(
                          experience,
                          'Ny erfarenhet'
                        )}
                        subtitle={formatDateSubtitle(experience)}
                        underline={index !== cv.workExperiences.length - 1}
                        font="body1"
                      >
                        <Text style={s.body1}>{experience.description}</Text>
                      </PdfSection>
                    ))}
                  </PdfStack>
                </PdfSection>
              )}
              {cv?.educations?.length > 0 && (
                <PdfSection
                  title={c.educationsHeading}
                  font="h2"
                  wrap={false}
                  break
                >
                  <PdfStack spacing={8}>
                    {cv?.educations?.map((education, index) => (
                      <PdfSection
                        key={education.organization}
                        title={formatExperienceTitle(
                          education,
                          'Ny utbildning'
                        )}
                        subtitle={formatDateSubtitle(education)}
                        underline={index !== cv.educations.length - 1}
                        font="body1"
                      >
                        <Text style={s.body1}>{education.description}</Text>
                      </PdfSection>
                    ))}
                  </PdfStack>
                </PdfSection>
              )}
            </PdfStack>
            <PdfStack spacing={8} id="sidebar" sidebar wrap={false}>
              <PdfSection
                title={c.contactHeading}
                font="h2"
                wrap={false}
                style={s.gutter5}
              >
                <View id="personal" wrap={false}>
                  <View
                    style={{
                      flexDirection: 'row',
                      gap: 8,
                    }}
                  >
                    <PhoneIphoneIcon />
                    <Text style={s.body1}>
                      <Link src={`tel:${cv?.phone}`} style={s.link}>
                        {cv?.phone}
                      </Link>
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: 'row',
                      gap: 8,
                    }}
                  >
                    <MailOutlineIcon />
                    <Text style={s.body1}>
                      <Link src={`mailto:${cv?.email}`} style={s.link}>
                        {cv?.email}
                      </Link>
                    </Text>
                  </View>
                </View>
              </PdfSection>
              {cv?.skills?.length > 0 && (
                <PdfSection title={c.skillsHeading} font="h2" style={s.gutter5}>
                  <Text style={s.body1}>{cv.skills.join(' • ')}</Text>
                </PdfSection>
              )}
              {cv?.references?.length > 0 && (
                <PdfSection
                  title={c.referencesHeading}
                  font="h2"
                  style={s.gutter5}
                >
                  {cv?.references?.filter(Boolean).map((reference, index) => (
                    <PdfSection
                      title={reference?.company ?? 'Ny referens'}
                      font="body2"
                      key={`references-${index}`}
                      underline={index !== cv.references.length - 1}
                    >
                      {reference.description && (
                        <Text
                          style={[s.body2, s.gutter3]}
                        >{`"${reference.description}"`}</Text>
                      )}
                      {reference.name && (
                        <Text style={[s.body2, s.gutter3]}>
                          {reference.name}
                        </Text>
                      )}
                      <Text style={[s.body2, s.gutter3]}>
                        <Link src={`tel:${reference?.phone}`} style={s.link}>
                          {reference?.phone ?? 'Ej angivet'}
                        </Link>
                      </Text>
                      <Text style={s.body2}>
                        <Link src={`mailto:${reference?.email}`} style={s.link}>
                          {reference?.email ?? 'Ej angivet'}
                        </Link>
                      </Text>
                    </PdfSection>
                  ))}
                </PdfSection>
              )}
            </PdfStack>
          </PdfStack>
        </PdfStack>
      </Page>
    </Document>
  )
}
