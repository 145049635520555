import React from 'react'
import { Provider } from 'react-redux'
import { QueryParamProvider } from 'use-query-params'
import { ThemeProvider, createTheme } from '@mui/material'
import { defaultLocale, themeOptions } from '@trr/mui-theme'
import { GTMTracker } from '@trr/gtm-tracking'
import { AppShellDataProvider } from '@trr/app-shell-data'
import { deepmerge } from '@mui/utils'

import { AppShellConfigurationProps } from './App.types'
import { KeyRoute, AppShellRouter } from './Router'
import Store from './Store/configureStore'
import { SelectedJob } from './Features/SelectedJob/SelectedJob'
import { AppliedJobsPage } from './Features/AppliedJobsPage/AppliedJobsPage'
import { CustomReactRouterAdapter } from './Utils/Helpers/CustomReactRouterAdapter'
import { JobSearch } from './Features/JobSearch/JobSearch'
import { mergeContentWithFallback } from './Utils/Content/mergeContentWithFallback'
import { SavedJobsPage } from './Features/SavedJobsPage/SavedJobsPage'
import { MatchedJobsPage } from './Features/MatchedJobsPage/MatchedJobsPage'
import { customThemeOptions } from './themeOptions'

const App: React.FunctionComponent<AppShellConfigurationProps> = ({
  currentKey,
  appShellData,
}) => {
  const theme = createTheme(
    deepmerge(themeOptions, customThemeOptions),
    defaultLocale
  )

  const content = mergeContentWithFallback(appShellData.content)
  return (
    <ThemeProvider theme={theme}>
      <GTMTracker mfName="job">
        <Provider store={Store}>
          <AppShellDataProvider value={{ ...appShellData, content }}>
            <QueryParamProvider
              adapter={CustomReactRouterAdapter}
              options={{ updateType: 'replaceIn' }}
            >
              <AppShellRouter currentKey={currentKey}>
                <KeyRoute urlKey={'search'}>
                  <JobSearch />
                </KeyRoute>
                <KeyRoute urlKey={'sparade'}>
                  <SavedJobsPage />
                </KeyRoute>
                <KeyRoute urlKey={'matchade'}>
                  <MatchedJobsPage />
                </KeyRoute>
                <KeyRoute urlKey={'ansokta'}>
                  <AppliedJobsPage />
                </KeyRoute>
                <KeyRoute urlKey={'annons'}>
                  <SelectedJob />
                </KeyRoute>
              </AppShellRouter>
            </QueryParamProvider>
          </AppShellDataProvider>
        </Provider>
      </GTMTracker>
    </ThemeProvider>
  )
}

export default App
