import React from 'react'
import { CardProps } from '@mui/material'
import { SearchRounded } from '@mui/icons-material'

import usePickEpiContent, {
  replaceEpiVariables,
} from '../../../../Utils/Content/usePickEpiContent'
import PuffCard from '../../../../Components/PuffCard'
import { formatLargeNumber } from '../../../../Api/helpers/searchJobAdHelpers'

interface TotalJobAdsPuffProps extends CardProps {
  totalJobAdsCount: number
}

const TotalJobAdsPuff: React.FC<TotalJobAdsPuffProps> = ({
  totalJobAdsCount,
  ...otherProps
}) => {
  const { categorization, title, subtitle } =
    usePickEpiContent().sokNy.totalJobAdsPuff

  return (
    <PuffCard
      overline={categorization}
      heading={replaceEpiVariables(title, {
        count: formatLargeNumber(totalJobAdsCount),
      })}
      description={subtitle}
      Icon={SearchRounded}
      {...otherProps}
    />
  )
}

export default TotalJobAdsPuff
