import React from 'react'
import { CardProps } from '@mui/material'

import PuffCard from '../../../../Components/PuffCard'
import usePickEpiContent, {
  replaceEpiVariables,
} from '../../../../Utils/Content/usePickEpiContent'
import { TargetIcon } from '../../../../Components/Icons/TargetIcon'

interface MatchingAdsPuffProps extends CardProps {
  matchingAdsCount: number
}

const MatchingAdsPuff: React.FC<MatchingAdsPuffProps> = ({
  matchingAdsCount,
  ...otherProps
}) => {
  const {
    sokNy: {
      matchingAdsPuff: {
        categorization,
        title,
        action,
        subtitle,
        to,
        titleSingle,
      },
    },
    matchadeNy: { urlKey: matchedAdsUrlKey },
  } = usePickEpiContent()

  const url = to + matchedAdsUrlKey

  const heading =
    matchingAdsCount === 1
      ? titleSingle
      : replaceEpiVariables(title, {
          count: matchingAdsCount.toString(),
        })

  return (
    <PuffCard
      overline={categorization}
      heading={heading}
      description={subtitle}
      Icon={TargetIcon}
      href={url}
      actionText={action}
      {...otherProps}
    />
  )
}

export default MatchingAdsPuff
