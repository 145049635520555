import { useFeatureFlag } from '@local/Utils'

declare global {
  interface Window {
    hj: (type: string, data: string) => void
  }
}

export const useHotjar = () => {
  const { isHotjarEnabled } = useFeatureFlag()

  const sendEvent = (eventName) => {
    if (isHotjarEnabled) {
      if (window?.hj !== undefined) {
        window.hj('event', eventName)
      } else {
        // eslint-disable-next-line no-console
        console.error('Hotjar is not defined')
      }
    }
  }

  return { sendEvent }
}
