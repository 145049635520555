import React from 'react'
import { Grid2 as Grid, TextField } from '@mui/material'
import { useFormikContext } from 'formik'
import { SmartAccordion } from '@local/Components/Accordion'
import { useContainerQuery } from '@local/Utils/Hooks/useContainerQuery'

import { Cv } from '../../GeneratedCv.types'

export const PersonalInfoField = () => {
  const { values, handleChange } = useFormikContext<Cv>()
  const { query } = useContainerQuery()

  return (
    <SmartAccordion title="Om mig">
      <Grid container spacing={2} pt={1}>
        <Grid size={query.down('md') ? 12 : 4}>
          <TextField
            label="Namn"
            value={values?.fullname}
            name="fullname"
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid size={query.down('sm') ? 12 : query.down('md') ? 6 : 4}>
          <TextField
            label="Mail"
            value={values?.email}
            name="email"
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid size={query.down('sm') ? 12 : query.down('md') ? 6 : 4}>
          <TextField
            label="Telefonnummer"
            value={values?.phone}
            name="phone"
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid size={12}>
          <TextField
            label="Om mig"
            placeholder="Om dig, berätta sammanfattat om dina styrkor och kunskaper"
            value={values?.summary}
            name="summary"
            onChange={handleChange}
            fullWidth
            rows={4}
            multiline
          />
        </Grid>
      </Grid>
    </SmartAccordion>
  )
}
