import { useAuthentication } from '@trr/app-shell-data'
import { useGetMatchedJobsCountQuery } from '@local/Features/MatchedJobsPage/Api/matchedJobsApi'

import { useGetSavedJobAdsQuery } from '../../../Api/SavedJobAdsApi'
import { useHasCompletedMatchingProfileQuery } from '../../../Api/matchingProfileApi'
import { useGetJobAdsCountQuery } from '../../../Api/searchJobAdApi'
import { OrderOption } from '../../../Api/types/api.types'
import usePickEpiContent from '../../../Utils/Content/usePickEpiContent'

const usePuffGrid = () => {
  const { sub } = useAuthentication()

  const {
    createMatchingProfilePuff,
    noMatchingAdsPuff,
    matchingAdsPuff,
    savedAdsPuff,
  } = usePickEpiContent().sokNy

  const {
    data: hasCompletedMatchingProfile,
    isLoading: isLoadingMatchingProfile,
    isError: hasMatchingProfileError,
  } = useHasCompletedMatchingProfileQuery(sub, {
    skip: !createMatchingProfilePuff,
  })

  const {
    data: savedJobAds,
    isLoading: isLoadingSavedJobAds,
    isError: hasSavedJobAdsError,
  } = useGetSavedJobAdsQuery(
    {
      page: 1,
      pageSize: 20,
      orderBy: OrderOption.SavedDate,
    },
    {
      skip: !savedAdsPuff,
    }
  )

  const {
    data: matchedJobCountResponse,
    isLoading: isLoadingMatchedJobsCount,
    isError: hasMatchedJobsCountError,
  } = useGetMatchedJobsCountQuery(undefined, {
    skip:
      (!noMatchingAdsPuff && !matchingAdsPuff) || !hasCompletedMatchingProfile,
  })

  const {
    data: jobAdSearchResult,
    isLoading: isLoadingJobAds,
    isError: hasJobAdsError,
  } = useGetJobAdsCountQuery()

  const hasError =
    hasMatchingProfileError ||
    hasSavedJobAdsError ||
    hasMatchedJobsCountError ||
    hasJobAdsError

  const isLoading =
    isLoadingMatchingProfile ||
    isLoadingSavedJobAds ||
    isLoadingMatchedJobsCount ||
    isLoadingJobAds

  return {
    hasCompletedMatchingProfile,
    savedAdsCount: savedJobAds?.totalHits ?? 0,
    matchingAdsCount: matchedJobCountResponse?.totalHits ?? 0,
    totalJobAdsCount: jobAdSearchResult?.totalHits ?? 0,
    isLoading,
    hasError,
    hasCreateMatchingProfilePuffContent: !!createMatchingProfilePuff,
    hasNoMatchingAdsPuffContent: !!noMatchingAdsPuff,
    hasMatchingAdsPuffContent: !!matchingAdsPuff,
    hasSavedAdsPuffContent: !!savedAdsPuff,
  }
}

export default usePuffGrid
