import React from 'react'
import { Box, Button, Typography } from '@mui/material'

interface CustomHighlightBoxProps {
  heading: string
  body: string
  linkTitle: string
  label: string
  url: string
}

export const CustomHighlightBox = (props: CustomHighlightBoxProps) => {
  return (
    <Box sx={{ background: '#edf0fd', padding: '24px', marginBottom: '24px' }}>
      <Typography variant="h4" gutterBottom>
        {props.heading}
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: '40px' }}>
        {props.body}
      </Typography>

      <Button href={props.url} title={props.linkTitle}>
        {props.label}
      </Button>
    </Box>
  )
}
