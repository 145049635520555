import React from 'react'
import { CardProps } from '@mui/material'

import usePickEpiContent from '../../../../Utils/Content/usePickEpiContent'
import PuffCard from '../../../../Components/PuffCard'
import { TargetIcon } from '../../../../Components/Icons/TargetIcon'

const NoMatchingAdsPuff: React.FC<CardProps> = (props) => {
  const { categorization, subtitle, action, to, title } =
    usePickEpiContent().sokNy.noMatchingAdsPuff

  return (
    <PuffCard
      overline={categorization}
      heading={title}
      description={subtitle}
      href={to}
      actionText={action}
      Icon={TargetIcon}
      {...props}
    />
  )
}

export default NoMatchingAdsPuff
