import React from 'react'
import { SmartAccordion } from '@local/Components/Accordion'
import {
  Button,
  Grid2 as Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useFormikContext } from 'formik'
import { DatePicker } from '@mui/x-date-pickers'
import { useContainerQuery } from '@local/Utils/Hooks/useContainerQuery'

import { Cv } from '../../GeneratedCv.types'

export const EducationField: React.FC = () => {
  const {
    values: { educations },
    handleChange,
    setFieldValue,
  } = useFormikContext<Cv>()

  const { query } = useContainerQuery()

  const removeEducation = (index: number) => {
    setFieldValue(
      'educations',
      educations?.filter((_, i) => i !== index)
    )
  }

  const addNewEmptyEducation = () => {
    setFieldValue('educations', [
      ...educations,
      {
        school: '',
        startDate: new Date().toISOString(),
        endDate: new Date().toISOString(),
        description: '',
      },
    ])
  }

  const handleStartDateChange = (value: Date | null, index) => {
    handleChange(`educations[${index}].startYear`)(
      value ? value?.getFullYear().toString() : ''
    )
    handleChange(`educations[${index}].startMonth`)(
      value ? value.toLocaleString('en', { month: 'long' }) : '' // TODO: different locales (languages) base on BE response
    )
  }

  const parseDate = (year: string, month: string): Date =>
    new Date(`${year}${month ? ` ${month}` : ''}`)

  const handleEndDateChange = (value: Date | null, index) => {
    handleChange(`educations[${index}].endYear`)(
      value ? value?.getFullYear().toString() : ''
    )
    handleChange(`educations[${index}].endMonth`)(
      value ? value.toLocaleString('en', { month: 'long' }) : ''
    )
  }

  return (
    <Stack gap={3}>
      <Typography variant="h6" pt={2} pb={2}>
        Utbildningar
      </Typography>
      {educations?.length > 0 && (
        <Stack gap={1}>
          {educations?.map((education, index) => {
            const educationParts = [
              education.title ? `${education.title}` : 'Ny utbildning',
              education.title ? ` på ${education.organization}` : '',
            ]
            return (
              <SmartAccordion
                title={`${educationParts[0]} ${educationParts[1]}`}
                typographyProps={{ variant: 'subtitle1' }}
                key={index}
                divider
              >
                <Grid container spacing={3}>
                  <Grid size={query.down('sm') ? 12 : 6}>
                    <TextField
                      label="Titel på utbildningen"
                      value={education.title}
                      name={`educations[${index}].title`}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid size={query.down('sm') ? 12 : 6}>
                    <TextField
                      label="Skola eller universitet"
                      value={education.organization}
                      name={`educations[${index}].organization`}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid size={query.down('sm') ? 12 : 6}>
                    <DatePicker
                      label={'Från'}
                      views={['month', 'year']}
                      maxDate={parseDate(education.endYear, education.endMonth)}
                      value={parseDate(
                        education.startYear,
                        education.startMonth
                      )}
                      onChange={(value) => handleStartDateChange(value, index)}
                      sx={{ width: '100%' }}
                    />
                  </Grid>

                  <Grid size={query.down('sm') ? 12 : 6}>
                    <DatePicker
                      label={'Till'}
                      views={['month', 'year']}
                      minDate={parseDate(
                        education.startYear,
                        education.startMonth
                      )}
                      value={parseDate(education.endYear, education.endMonth)}
                      onChange={(value) => handleEndDateChange(value, index)}
                      sx={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid size={12}>
                    <TextField
                      label="Beskrivning"
                      value={education.description}
                      name={`educations[${index}].description`}
                      onChange={handleChange}
                      rows={4}
                      multiline
                      fullWidth
                    />
                  </Grid>
                  <Button
                    onClick={() => removeEducation(index)}
                    variant="outlined"
                    sx={{ alignSelf: 'flex-end' }}
                    size="small"
                    fullWidth
                  >
                    Ta bort
                  </Button>
                </Grid>
              </SmartAccordion>
            )
          })}
        </Stack>
      )}
      <Button
        onClick={addNewEmptyEducation}
        size="small"
        variant="outlined"
        fullWidth
      >
        Ny utbildning
      </Button>
    </Stack>
  )
}
