import { baseApi } from '@local/Api/baseApi'
import { mapperLocationHelper } from '@local/Api/helpers/searchJobAdHelpers'

import {
  FacetResponseOptions,
  JobAdList,
  JobSearchHitResponse,
  QuickApplicationStatus,
} from '../../../Api/types/api.types'

interface MatchedJobsParams {
  orderBy: string
  page: number
  pageSize: number
}

export interface MatchedJobResponse {
  jobs: JobSearchHitResponse[]
  facets: FacetResponseOptions
  totalHits: number
  totalHitsWithDescription: number
  totalPages: number
}

export const matchedJobsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getMatchedJobs: builder.query<JobAdList, MatchedJobsParams>({
      providesTags: ['MatchedJobs'],
      query: (params) => ({
        url: '/jobs/search/matches',
        params,
      }),
      transformResponse: (response: MatchedJobResponse) => {
        return {
          jobAds: response.jobs.map((job) => ({
            id: job.id,
            header: job.title,
            employerName: job.employerName,
            locationName: mapperLocationHelper(job),
            jobTitle: job.professions.join(', '),
            description: job.descriptionShort,
            publishDate: job.publicationDate
              ? new Date(job.publicationDate)
              : undefined,
            deadline: job.applicationDeadline
              ? new Date(job.applicationDeadline)
              : undefined,
            isTrrRecruitment: job.isTrrRecruitment,
            applicationStatus: job.applied
              ? QuickApplicationStatus.Applied
              : null,
            isSaved: job.saved,
            isTrrJobAd: job.isTrrRecruitment,
            locationType: null,
            createdAt: null,
            lastModifiedAt: null,
          })),
          totalHits: response.totalHits,
          totalHitsWithDescription: response.totalHitsWithDescription,
          page: null,
          pageSize: null,
          totalPages: response.totalPages,
        }
      },
    }),
    getMatchedJobsCount: builder.query<MatchedJobResponse, void>({
      query: () => ({
        url: '/jobs/search/matches',
        params: {
          onlyCount: true,
        },
      }),
    }),
  }),
})

export const { useGetMatchedJobsQuery, useGetMatchedJobsCountQuery } =
  matchedJobsApi
