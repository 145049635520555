import React from 'react'
import { CardProps } from '@mui/material'
import { BookmarkBorderRounded } from '@mui/icons-material'

import PuffCard from '../../../../Components/PuffCard'
import usePickEpiContent, {
  replaceEpiVariables,
} from '../../../../Utils/Content/usePickEpiContent'

interface SavedAdsPuffProps extends CardProps {
  savedAdsCount: number
}

export const SavedAdsPuff: React.FC<SavedAdsPuffProps> = ({
  savedAdsCount,
  ...otherProps
}) => {
  const {
    sokNy: {
      savedAdsPuff: {
        categorization,
        title,
        action,
        subtitle,
        to,
        titleSingle,
      },
    },
    sparadeNy: { urlKey: savedAdsUrlKey },
  } = usePickEpiContent()

  const url = to + savedAdsUrlKey

  const heading =
    savedAdsCount === 1
      ? titleSingle
      : replaceEpiVariables(title, {
          count: savedAdsCount.toString(),
        })

  return (
    <PuffCard
      overline={categorization}
      heading={heading}
      description={subtitle}
      href={url}
      actionText={action}
      Icon={BookmarkBorderRounded}
      {...otherProps}
    />
  )
}

export default SavedAdsPuff
