import React from 'react'
import { JobCard } from '@local/Components/JobCard/JobCard'
import { JobAd } from '@local/Api/types/api.types'
import { Chip, Stack, Typography } from '@mui/material'
import usePickEpiContent from '@local/Utils/Content/usePickEpiContent'
import { useSearchQueryParams } from '@local/Utils/Hooks/useSearchQueryParams'
import { date } from '@trr/frontend-datetime'
import { SaveJobButton } from '@local/Features/SavedJobsPage/SaveJobButton/SaveJobButton'
import { Deadline } from '@local/Components/Deadline/Deadline'

import { NewIndicator } from './NewIndicator'

interface SearchJobListItemprops {
  job: JobAd
  showNewIndicator?: boolean
}

export const SearchJobListItem: React.FC<SearchJobListItemprops> = ({
  job,
  showNewIndicator = false,
}) => {
  const {
    searchParams: { historicSearchTimeStamp },
  } = useSearchQueryParams()
  const jobAdLink = usePickEpiContent().sokNy.resultList.jobAdLink

  const hasApplied = !!job.applicationStatus

  const renderApplyInfo = () => {
    if (hasApplied) {
      return (
        <Chip label={'Ansökt'} variant="status-success" sx={{ height: 0 }} />
      )
    }

    if (job.deadline) {
      return <Deadline variant="body2" date={new Date(job.deadline)} />
    }
  }

  const subtitle = [job.jobTitle, job.employerName, job.locationName]
    .filter(Boolean)
    .join(', ')

  return (
    <JobCard
      href={`${jobAdLink}${job.id}`}
      title={job?.header}
      subtitle={subtitle}
      preTitle={job.isTrrJobAd ? 'I samarbete med TRR' : 'Publik annons'}
      preTitleVariant={job.isTrrJobAd ? 'highlight' : 'info'}
      actionIcon={
        hasApplied ? undefined : (
          <SaveJobButton jobId={job.id} isSaved={job.isSaved} />
        )
      }
      description={job.description}
      footer={
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Stack direction="row" alignItems="baseline">
            {showNewIndicator && (
              <NewIndicator
                publishDate={job.publishDate}
                lastSearchDate={
                  historicSearchTimeStamp
                    ? new Date(historicSearchTimeStamp)
                    : undefined
                }
              />
            )}
            <Typography variant="body2" color="text.secondary">
              {date.format(job.publishDate, { year: true })}
            </Typography>
          </Stack>
          {renderApplyInfo()}
        </Stack>
      }
    />
  )
}
