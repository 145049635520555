import React from 'react'
import {
  Button,
  Grid2 as Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useFormikContext } from 'formik'
import { DatePicker } from '@mui/x-date-pickers'
import { Add, Delete } from '@mui/icons-material'
import { SmartAccordion } from '@local/Components/Accordion'
import { useContainerQuery } from '@local/Utils/Hooks/useContainerQuery'

import { Cv } from '../../GeneratedCv.types'

export const WorkExperienceField: React.FC = () => {
  const {
    values: { workExperiences },
    handleChange,
    setFieldValue,
  } = useFormikContext<Cv>()

  const { query } = useContainerQuery()

  const removeExperience = (index: number) => {
    setFieldValue(
      'workExperiences',
      workExperiences?.filter((_, i) => i !== index)
    )
  }

  const addNewEmptyExperience = () => {
    setFieldValue('workExperiences', [
      ...(workExperiences ?? []),
      {
        jobTitle: '',
        employer: '',
        startDate: new Date().toISOString(),
        endDate: new Date().toISOString(),
        description: '',
      },
    ])
  }

  const parseDate = (year: string, month: string): Date =>
    new Date(`${year}${month ? ` ${month}` : ''}`)

  const handleStartDateChange = (value: Date | null, index) => {
    handleChange(`workExperiences[${index}].startYear`)(
      value ? value?.getFullYear().toString() : ''
    )
    handleChange(`workExperiences[${index}].startMonth`)(
      value ? value.toLocaleString('en', { month: 'long' }) : ''
    )
  }

  const handleEndDateChange = (value: Date | null, index) => {
    handleChange(`workExperiences[${index}].endYear`)(
      value ? value?.getFullYear().toString() : ''
    )
    handleChange(`workExperiences[${index}].endMonth`)(
      value ? value.toLocaleString('en', { month: 'long' }) : ''
    )
  }

  return (
    <Stack gap={3}>
      <Typography variant="h6" pt={2} pb={2}>
        Yrkeserfarenheter
      </Typography>
      {workExperiences?.length > 0 && (
        <Stack gap={1}>
          {workExperiences?.map((experience, index) => {
            const jobTitleParts = [
              experience.title ? `${experience.title}` : 'Ny yrkestitel',
              experience.title ? ` på ${experience.organization}` : '',
            ]
            return (
              <SmartAccordion
                title={`${jobTitleParts[0]} ${jobTitleParts[1]}`}
                typographyProps={{ variant: 'subtitle1' }}
                key={index}
                divider
              >
                <Grid container spacing={3}>
                  <Grid size={query.down('sm') ? 12 : 6}>
                    <TextField
                      label="Roll"
                      name={`workExperiences[${index}].title`}
                      value={experience.title}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid size={query.down('sm') ? 12 : 6}>
                    <TextField
                      label="Företag"
                      name={`workExperiences[${index}].organization`}
                      value={experience.organization}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid size={query.down('sm') ? 12 : 6}>
                    <DatePicker
                      label={'Från'}
                      maxDate={parseDate(
                        experience.endYear,
                        experience.endMonth
                      )}
                      value={parseDate(
                        experience.startYear,
                        experience.startMonth
                      )}
                      views={['month', 'year']}
                      onChange={(value) => handleStartDateChange(value, index)}
                      sx={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid size={query.down('sm') ? 12 : 6}>
                    <DatePicker
                      label={'Till'}
                      minDate={parseDate(
                        experience.startYear,
                        experience.startMonth
                      )}
                      value={parseDate(experience.endYear, experience.endMonth)}
                      views={['month', 'year']}
                      onChange={(value) => handleEndDateChange(value, index)}
                      sx={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid size={12}>
                    <TextField
                      label="Arbetsbeskrivning"
                      value={experience.description}
                      name={`workExperiences[${index}].description`}
                      onChange={handleChange}
                      multiline
                      rows={4}
                      fullWidth
                    />
                  </Grid>
                  <Button
                    onClick={() => removeExperience(index)}
                    size="small"
                    variant="outlined"
                    fullWidth
                    sx={{ alignSelf: 'flex-end' }}
                    startIcon={<Delete />}
                  >
                    Ta bort
                  </Button>
                </Grid>
              </SmartAccordion>
            )
          })}
        </Stack>
      )}
      <Button
        onClick={addNewEmptyExperience}
        size="small"
        variant="outlined"
        fullWidth
        startIcon={<Add />}
      >
        Ny yrkeserfarenhet
      </Button>
    </Stack>
  )
}
